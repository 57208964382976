<template>
  <div>
    <a-form-model layout="vertical" :model="form"
                  :rules="rules" ref="form">
      <a-card class="card" title="基本信息" :bordered="false">
        <div slot="extra">
          <a-button ><router-link :to="{ name: 'Customer'}">取消</router-link></a-button>
          <a-divider type="vertical" />
          <a-button type="primary" @click="handleSubmit">保存</a-button>
        </div>
        <a-row :gutter="20">
          <a-col :span="6">
            <a-form-model-item label="客户简称" prop="short_name">
              <a-input v-model.trim="form.short_name" @change="handleNameChange"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="简码">
              <a-input v-model="form.abbr" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="客户全称">
              <a-input v-model.trim="form.full_name" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="开票税率">
              <a-input v-model="form.tax_rate" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="共享档案">
              <a-select
                showSearch
                allowClear
                placeholder="请选择共享档案"
                style="width: 100%"
                v-model="form.share_type"
              >
                <a-select-option :key="0">私有</a-select-option>
                <a-select-option :key="1">公有</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="客户地址">
              <a-input v-model="form.address" />
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="备注">
              <a-textarea v-model="form.remark" />
            </a-form-model-item>
          </a-col>
          <!-- <a-col :span="24">
            <a-form-model-item label="进仓编号设置">
            </a-form-model-item>
          </a-col> -->
        </a-row>
      </a-card>
      <a-card class="card prefix-tags" title="进仓编号设置" :bordered="false">
        <template v-for="(tag, index) in numPrefixs">
          <a-tag :key="index" closable
                 color="green" @close="() => handleClose(tag)">
            {{ tag }}
          </a-tag>
        </template>
        <a-input
          v-if="inputVisible"
          ref="input"
          type="text"
          :style="{ width: '100px' }"
          v-model="inputValue"
          @keyup.enter="handleInputConfirm"
        />
        <a-tag v-else @click="showInput" style="background: #fff; borderStyle: dashed;">
          <a-icon type="plus" /> 添加进仓编号
        </a-tag>
      </a-card>
      <a-card class="card prefix-tags" title="开票信息设置" :bordered="false">
        <a-row :gutter="20">
          <a-col :span="6">
            <a-form-model-item label="税号">
              <a-input v-model="form.tax_no" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="开户行">
              <a-input v-model="form.bank_name" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="银行卡账号">
              <a-input v-model="form.bank_card_no" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-card>
    </a-form-model>
    <a-card class="card" title="联系人设置" :bordered="false">
      <a-table bordered :dataSource="data"
               :columns="columns" :pagination="false"
               rowKey="id" size="small">
        <template slot="name" slot-scope="text, record">
          <a-input :value="text"
                   @change="e => handleChange(e.target.value, record.id, 'name')"/>
        </template>
        <template slot="op_num" slot-scope="text, record">
          <a-input :value="text" @change="e => handleChange(e.target.value, record.id, 'op_num')"/>
        </template>
        <template slot="tel_num" slot-scope="text, record">
          <a-input :value="text" @change="e => handleChange(e.target.value, record.id, 'tel_num')"/>
        </template>
        <template slot="phone" slot-scope="text, record">
          <a-input :value="text" @change="e => handleChange(e.target.value, record.id, 'phone')"/>
        </template>
        <template slot="email" slot-scope="text, record">
          <a-input :value="text" @change="e => handleChange(e.target.value, record.id, 'email')"/>
        </template>
        <template slot="is_default" slot-scope="text, record">
          <a-switch :defaultChecked="text" checkedChildren="是" unCheckedChildren="否" @change="v => handleChange(v, record.id, 'is_default')"/>
        </template>
        <template slot="remark" slot-scope="text, record">
          <a-textarea :value="text" @change="e => handleChange(e.target.value, record.id, 'remark')"/>
        </template>
        <template slot="operation" slot-scope="text, record">
          <a-popconfirm
            v-if="data.length"
            title="确认删除?"
            @confirm="() => onDelete(record.id)"
          >
            <a href="javascript:;"><a-icon type="delete" :style="{ fontSize: '18px' }"/></a>
          </a-popconfirm>
        </template>
      </a-table>
      <a-button style="width: 100%; margin-top: 16px; margin-bottom: 8px" type="dashed" icon="plus" @click="handleAdd">新增联系人</a-button>
    </a-card>
  </div>
</template>

<script>
import { saveCommonInfo, getCustomerContactList } from '@/api/common'
import { getFirstLetter } from '@/utils/util'
export default {
  data() {
    return {
      form: {
        short_name: '',
        abbr: '',
        full_name: '',
        tax_rate: '',
        type: null,
        share_type: null,
        address: '',
        remark: '',
        tax_no: '',
        bank_name: '',
        bank_card_no: ''
      },
      isEdit: false,
      data: [],
      numPrefixs: [], // 进仓编号
      inputValue: '',
      inputVisible: false,
      rules: {
        short_name: [
          { required: true, message: '请输入客户简称', trigger: 'blur' }
        ]
      },
      columns: [{
          title: '操作',
          scopedSlots: { customRender: 'operation' },
          align: 'center',
          width: 60
        },
        {
          title: '姓名',
          dataIndex: 'name',
          width: 80,
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '操作编号',
          dataIndex: 'op_num',
          width: 80,
          scopedSlots: { customRender: 'op_num' }
        },
        {
          title: '固定电话',
          dataIndex: 'tel_num',
          width: 100,
          scopedSlots: { customRender: 'tel_num' }
        },
        {
          title: '手机号码',
          dataIndex: 'phone',
          width: 100,
          scopedSlots: { customRender: 'phone' }
        },
        {
          title: '邮箱',
          dataIndex: 'email',
          width: 100,
          scopedSlots: { customRender: 'email' }
        },
        {
          title: '是否默认',
          dataIndex: 'is_default',
          width: 100,
          scopedSlots: { customRender: 'is_default' }
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 100,
          scopedSlots: { customRender: 'remark' }
        }]
    }
  },
  watch: {
    $route: function(newRoute) {
      if (!this.queryParam && newRoute.name === 'CustomerCUR') { // 编辑页路由监控
        if (newRoute.params.id === 'create') { // 新增
          this.isEdit = false
          this.$route.meta.title = '新增客户信息'
          this.numPrefixs = []
          this.form = {
            short_name: '',
            abbr: '',
            full_name: '',
            tax_rate: '',
            type: null,
            share_type: null,
            address: '',
            remark: ''
          }
          this.$refs.form.clearValidate()
          this.data = []
        } else {
          this.form = newRoute.params
          this.numPrefixs = this.form.wms_in_num_prefix
          this.getEditData(newRoute.params.id)
        }
      }
    }
  },
  mounted() {
    if (this.$route.params.id !== 'create') {
      this.form = this.$route.params
      this.getEditData(this.$route.params.id)
    }
  },
  methods: {
    getEditData(id) {
      this.isEdit = true
      this.$route.meta.title = '编辑客户信息'
      getCustomerContactList({ id: id }).then(v => {
        this.data = v
      })
    },
    handleClose(removedTag) {
      const tags = this.numPrefixs.filter(tag => tag !== removedTag);
      this.numPrefixs = tags;
    },
    handleNameChange() {
      this.form.abbr = getFirstLetter(this.form.short_name)
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(function() {
        this.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      const inputValue = this.inputValue;
      let tags = this.numPrefixs;
      if (inputValue && tags.indexOf(inputValue) === -1) {
        tags = [...tags, inputValue];
      }
      Object.assign(this, {
        numPrefixs: tags,
        inputVisible: false,
        inputValue: ''
      });
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          // let func = createCommonInfo
          // if (this.isEdit) {
          //   func = updateCommonInfo
          // }
          const data = {
            customer: { ...this.form, wms_in_num_prefix: this.numPrefixs },
            contact_list: this.data
          }
          saveCommonInfo('customer', data).then(_ => {
              this.confirmLoading = false
              this.formVisible = false
              this.$notification['success']({
                message: '提示',
                description: this.isEdit ? '编辑成功' : '创建成功.'
              })
              this.$router.push({
                name: 'Customer',
                params: data.customer,
                query: {
                  isEdit: this.isEdit
                }
              })
            }).catch(_ => {
              this.formVisible = false
              this.confirmLoading = false
            })
        } else {
          return false;
        }
      })
    },
    handleAdd() {
      const { data } = this;
      const newData = {
        id: Date.now(),
        name: '',
        op_num: '',
        tel_num: '',
        phone: '',
        email: '',
        is_default: false,
        remark: ''
      };
      this.data = [...data, newData]
    },
    onDelete(key) {
      const data = [...this.data];
      this.data = data.filter(item => item.id !== key);
    },
    handleChange (value, key, column) {
      const newData = [...this.data]
      const target = newData.find(item => key === item.id)
      if (target) {
        target[column] = value
        this.data = newData
      }
    }
  }
}
</script>

<style scoped>
  .prefix-tags .ant-tag{
    font-size: 14px;
    display: inline-block;
    line-height: 32px;
  }
</style>
